// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appSubCategories/getAllData', async (id) => {
  const response = await axios.get('/api/sub-category/list/all-data', { id })
  //console.log("response: ", response.data)
  //console.log("response: ", response)
  return response.data.documents
})

export const getDocumentFile = createAsyncThunk('appSubCategories/getDocumentFile', async (id) => {
  const response = await axios.get('/api/sub-category/document/get-file', { id })
  return response.data.documents
})

export const getData = createAsyncThunk('appSubCategories/getData', async params => {
  //console.log("params: ", params)
  const response = await axios.get('/api/sub-category/list/data', params)
  //console.log("response: ", response.data)
  return {
    params,
    data: response.data.documents,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appSubCategories/getSubCategory', async id => {
  const response = await axios.get('/api/sub-category/sub-category', { id })
  return response.data.user
})

export const appSubCategoriesSlice = createSlice({
  name: 'appSubCategories',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appSubCategoriesSlice.reducer
