import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const baseUrl = process.env.REACT_APP_ENDPOINT_URL
const documents = []

// GET ALL DATA
mock.onGet('/api/document/list/all-data').reply(async() => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  const res = await fetch(`${baseUrl}/document`, {
    headers: {
      Authorization: `${userData.accessToken}`
    }
  })
  const content = await res.json()
  return [
    200,
    data.documents
  ]
})

// POST: Add new document
mock.onPost('/apps/document/add-document').reply(async(config) => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  // Get event from post data
  const res = await fetch(`${baseUrl}/document`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${userData.accessToken}`
    },
    body: config.data
  })
  const content = await res.json()

  const document = JSON.parse(config.data)
  const highestValue = data.documents.reduce((a, b) => (a.id > b.id ? a : b)).id

  document.id = highestValue + 1

  documents.push(document)

  return [201, { document }]
})

// POST: edit document
mock.onPost('/apps/document/edit-document').reply(async(config) => {

  const userData = JSON.parse(localStorage.getItem('userData'))

  const res = await fetch(`${baseUrl}/document/${config.data.id}`, {
    method: 'POST',
    headers: {
      Authorization: `${userData.accessToken}`,
      'Content-Type': 'application/json'
    },
    body: config.data
  })
  const content = await res.json()

  const document = JSON.parse(config.data)
  const highestValue = data.documents.reduce((a, b) => (a.id > b.id ? a : b)).id

  document.id = highestValue + 1

  documents.push(document)

  return [201, { document }]
})

// GET Updated DATA
mock.onGet('/api/document/list/data').reply(async (config) => {
  const {
    q = '',
    page = 1,
    role = null,
    perPage = 10,
    sort = 'asc',
    status = null,
    currentPlan = null,
    sortColumn = 'fullName'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const userData = JSON.parse(localStorage.getItem('userData'))
  const res = await fetch(`${baseUrl}/document`, {
    headers: {
      Authorization: `${userData.accessToken}`
    }
  })
  let content = await res.json()
  let documentData = []
  for (let i in content) {
      let date = new Date(content[i]['date'])
      content[i]['date'] = date.toISOString().split('T')[0]
      documentData.push(content[i])
  }
  //categories = content

  const dataAsc = documentData.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    documents =>
      (documents.name.toLowerCase().includes(queryLowered))
  )
  /* eslint-enable  */
    //console.log("filteredData: ", filteredData.length)
  return [
    200,
    {
      total: filteredData.length,
      documents: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET document
mock.onGet('/apps/document/user').reply(async (config) => {
  const { id } = config
  const user = data.users.find(i => i.id === id)
  return [200, { user }]
})

// DELETE: Deletes document
mock.onDelete('/apps/document/delete').reply(async (config) => {
  
  const userData = JSON.parse(localStorage.getItem('userData'))

  let documentId = config.id

  // Convert Id to number
  documentId = Number(documentId)
  const documentObj = {
    id: documentId
  }

  const res = await fetch(`${baseUrl}/document`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${userData.accessToken}`
    },
    body: JSON.stringify(documentObj)
  })

  const documentIndex = data.documents.findIndex(t => t.id === documentId)
  data.documents.splice(documentIndex, 1)

  return [200]
})
