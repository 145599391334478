// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appDocuments/getAllData', async () => {
  const response = await axios.get('/api/document/list/all-data')
  //console.log("response: ", response.data)
  //console.log("response: ", response)
  return response.data
})

export const getData = createAsyncThunk('appDocuments/getData', async params => {
  const response = await axios.get('/api/document/list/data', params)
  //console.log("params: ", params)
  return {
    params,
    data: response.data.documents,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appDocuments/getUser', async id => {
  const response = await axios.get('/api/document/user', { id })
  return response.data.user
})

export const addDocument = createAsyncThunk('appDocuments/addDocument', async (document, { dispatch, getState }) => {
  await axios.post('/apps/document/add-document', document)
  await dispatch(getData(getState().document.params))
  await dispatch(getAllData())
  return document
})

export const editDocument = createAsyncThunk('appDocuments/editDocument', async (document, { dispatch, getState }) => {
  await axios.post('/apps/document/edit-document', document)
  await dispatch(getData(getState().document.params))
  await dispatch(getAllData())
  return document
})

export const deleteDocument = createAsyncThunk('appDocuments/deleteDocument', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/document/delete', { id })
  await dispatch(getData(getState().document.params))
  await dispatch(getAllData())
  return id
})

export const appDocumentsSlice = createSlice({
  name: 'appDocuments',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appDocumentsSlice.reducer
