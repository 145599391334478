// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appCategories/getAllData', async () => {
  const response = await axios.get('/api/category/list/all-data')
  //console.log("response: ", response.data)
  //console.log("response: ", response)
  return response.data
})

export const getData = createAsyncThunk('appCategories/getData', async params => {
  const response = await axios.get('/api/category/list/data', params)
  return {
    params,
    data: response.data.categories,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appCategories/getUser', async id => {
  const response = await axios.get('/api/category/user', { id })
  return response.data.user
})

export const addCategory = createAsyncThunk('appCategories/addCategory', async (category, { dispatch, getState }) => {
  await axios.post('/apps/category/add-category', category)
  await dispatch(getData(getState().category.params))
  await dispatch(getAllData())
  return category
})

export const editCategory = createAsyncThunk('appCategories/editCategory', async (category, { dispatch, getState }) => {
  await axios.post('/apps/category/edit-category', category)
  await dispatch(getData(getState().category.params))
  await dispatch(getAllData())
  return category
})

export const deleteCategory = createAsyncThunk('appCategories/deleteCategory', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/category/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appCategoriesSlice = createSlice({
  name: 'appCategories',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appCategoriesSlice.reducer
