import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const baseUrl = process.env.REACT_APP_ENDPOINT_URL
const categories = []

// GET ALL DATA
mock.onGet('/api/category/list/all-data').reply(async() => {
  const res = await fetch(`${baseUrl}/sub-category`)
  const content = await res.json()
  return [
    200,
    content
  ]
})

// POST: Add new category
mock.onPost('/apps/category/add-category').reply(async(config) => {
  // Get event from post data
  const res = await fetch(`${baseUrl}/sub-category`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: config.data
  })
  const content = await res.json()

  const category = JSON.parse(config.data)
  const highestValue = data.categories.reduce((a, b) => (a.id > b.id ? a : b)).id

  category.id = highestValue + 1

  categories.push(user)

  return [201, { category }]
})

// POST: Edit category
mock.onPost('/apps/category/edit-category').reply(async(config) => {
  
  const userData = JSON.parse(localStorage.getItem('userData'))

  const res = await fetch(`${baseUrl}/sub-category/:id`, {
    method: 'POST',
    headers: {
      Authorization: `${userData.accessToken}`,
      'Content-Type': 'application/json'
    },
    body: config.data
  })
  const content = await res.json()

  const category = JSON.parse(config.data)
  const highestValue = data.categories.reduce((a, b) => (a.id > b.id ? a : b)).id

  category.id = highestValue + 1

  categories.push(user)

  return [201, { category }]
})

// GET Updated DATA
mock.onGet('/api/category/list/data').reply(async (config) => {
  const {
    q = '',
    page = 1,
    role = null,
    perPage = 10,
    sort = 'asc',
    status = null,
    currentPlan = null,
    sortColumn = 'fullName'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const res = await fetch(`${baseUrl}/sub-category`)
  let content = await res.json()
  //categories = content

  const dataAsc = content.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    categories =>
      (categories.name.toLowerCase().includes(queryLowered) ||
      categories.category.name.toLowerCase().includes(queryLowered))
  )
  /* eslint-enable  */
    //console.log("filteredData: ", filteredData.length)
  return [
    200,
    {
      total: filteredData.length,
      categories: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET category
mock.onGet('/apps/categories/user').reply(async (config) => {
  const { id } = config
  const user = data.users.find(i => i.id === id)
  return [200, { user }]
})

// DELETE: Deletes User
mock.onDelete('/apps/category/delete').reply(async (config) => {
  // Get user id from URL
  let categoryId = config.id

  // Convert Id to number
  categoryId = Number(categoryId)
  const categoryObj = {
    id: categoryId
  }
  console.log("categoryObj: ", categoryObj)
  //console.log("categoryObj: ", categoryObj)

  const res = await fetch(`${baseUrl}/sub-category`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(categoryObj)
  })

  const categoryIndex = data.categories.findIndex(t => t.id === categoryId)
  data.categories.splice(categoryIndex, 1)

  return [200]
})
