import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const baseUrl = process.env.REACT_APP_ENDPOINT_URL
const documents = []

// GET ALL DATA
mock.onGet('/api/sub-category/list/all-data').reply(async(id) => {
  const res = await fetch(`${baseUrl}/document`)
  const content = await res.json()
  return [
    200,
    data.documents
  ]
})

// GET FILE
mock.onGet('/api/sub-category/document/get-file').reply(async(id) => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  const res = await fetch(`${baseUrl}/document/file/${id.id}`, {
    headers: {
      Authorization: `${userData.accessToken}`
    }
  })
  const content = await res.json()
  return [
    200,
    data.documents
  ]
})

// GET Updated DATA
mock.onGet('/api/sub-category/list/data').reply(async(config) => {
  const {
    id = '',
    q = '',
    page = 1,
    role = null,
    perPage = 10,
    sort = 'asc',
    status = null,
    currentPlan = null,
    sortColumn = 'name'
  } = config
  //const id = parseInt(config.id)
  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const userData = JSON.parse(localStorage.getItem('userData'))
  const res = await fetch(`${baseUrl}/document?subCategoryId=${id}`, {
    method: 'GET',
    headers: {
      Authorization: `${userData.accessToken}`,
      'Content-Type': 'application/json'
    },
  })
  let content = await res.json()
  let documentData = []
  for (let i in content) {
      let date = new Date(content[i]['date'])
      content[i]['date'] = date.toISOString().split('T')[0]
      documentData.push(content[i])
  }
  //categories = content

  const dataAsc = documentData.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    documents =>
      (documents.name.toLowerCase().includes(queryLowered))
  )
  /* eslint-enable  */
    //console.log("filteredData: ", filteredData.length)
  return [
    200,
    {
      total: filteredData.length,
      documents: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET document
mock.onGet('/apps/sub-category/sub-category').reply(async (config) => {
  const { id } = config
  const user = data.users.find(i => i.id === id)
  return [200, { user }]
})
