import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const baseUrl = process.env.REACT_APP_ENDPOINT_URL
let users = []

/*const getUsers = async() => {
  const res = await fetch(`${baseUrl}/user`)
  const content = await res.json()
  return content
}*/

// GET ALL DATA
mock.onGet('/api/users/list/all-data').reply(async() => {
  const res = await fetch(`${baseUrl}/user`)
  const content = await res.json()
  return [
    200,
    data.users
  ]
})

// POST: Add new user
mock.onPost('/apps/users/add-user').reply(async(config) => {

  const userData = JSON.parse(localStorage.getItem('userData'))

  const res = await fetch(`${baseUrl}/user`, {
    method: 'POST',
    headers: {
      Authorization: `${userData.accessToken}`,
      'Content-Type': 'application/json'
    },
    body: config.data
  })
  const content = await res.json()
  if (content.message) {
    return [500, content]
  } else {
  const user = JSON.parse(config.data)
  const highestValue = users.reduce((a, b) => (a.id > b.id ? a : b)).id

  user.id = highestValue + 1

  users.push(user)

  return [201, { user }]
  }
})

// POST: Edit user
mock.onPost('/apps/users/edit-user').reply(async(config) => {
  
  const userId = config.data.id

  const userData = JSON.parse(localStorage.getItem('userData'))

  const res = await fetch(`${baseUrl}/user/${userData.id}`, {
    method: 'POST',
    headers: {
      Authorization: `${userData.accessToken}`,
      'Content-Type': 'application/json'
    },
    body: config.data
  })
  const content = await res.json()

  return [201, { content }]
})

// GET Updated DATA
mock.onGet('/api/users/list/data').reply(async (config) => {
  const {
    q = '',
    page = 1,
    role = null,
    perPage = 10,
    sort = 'asc',
    status = null,
    currentPlan = null,
    sortColumn = 'fullName'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const res = await fetch(`${baseUrl}/user`)
  let content = await res.json()

  for (let i in content) {
    content[i].role = content[i].user_roles.value
  }
  users = content
  const dataAsc = content.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))
  //console.log("dataAsc: ", dataAsc)

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(
    user =>
      (user.email.toLowerCase().includes(queryLowered) || user.fullName.toLowerCase().includes(queryLowered))
      //user.role === (role || user.role) &&
      //user.currentPlan === (currentPlan || user.currentPlan) &&
      //user.status === (status || user.status)
  )
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      users: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET USER
mock.onGet('/api/users/user').reply(config => {
  const { id } = config
  const user = data.users.find(i => i.id === id)
  return [200, { user }]
})

// DELETE: Deletes User
mock.onDelete('/apps/users/delete').reply(async (config) => {
  // Get user id from URL
  let userId = config.id

  // Convert Id to number
  userId = Number(userId)

  const userObj = {
    id: userId
  }

  const userData = JSON.parse(localStorage.getItem('userData'))

  const res = await fetch(`${baseUrl}/user`, {
    method: 'DELETE',
    headers: {
      Authorization: `${userData.accessToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(userObj)
  })

  const userIndex = data.users.findIndex(t => t.id === userId)
  data.users.splice(userIndex, 1)

  return [200]
})
